import {
  Box,
  Fade,
  SimpleGrid,
  Skeleton,
  VStack,
  Text,
} from '@chakra-ui/react';
import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import TopActionBar from '../components/common/TopActionBar';
import { CommunityExperienceListItem } from '../interfaces';
import { useCommunityStore } from '../store/community';
import CommunityItem from '../components/communityView/CommunityItem';
import CommunityBarActions from '../components/barActions/CommunityBarActions';
import CommunityFilters from '../components/communityView/CommunityFilters';
import { Star } from '../Icons/Star';
import { Globe } from '../Icons/Globe';

const CommunityExperiencesView = () => {
  const {
    queryName,
    totalCommunityExperiencesPages,
    fetchCommunityExperiences,
    likes,
    tags,
    mostRecent,
    isFeaturedExperiences,
    setIsExperienceDelisted,
    isExperienceDelisted,
  } = useCommunityStore();

  const [localCommunityExperiences, setLocalCommunityExperiences] = useState<
    CommunityExperienceListItem[]
  >([]);
  const [communityExperiencesLoaded, setCommunityExperiencesLoaded] =
    useState(false);
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);

  const isValidNumber = (value: string | number) =>
    typeof value === 'number' && !isNaN(value);

  const loadMoreExperiences = useCallback(() => {
    const total = isValidNumber(totalCommunityExperiencesPages)
      ? totalCommunityExperiencesPages
      : 0;
    if (loader || page >= Number(total - 1)) return;

    setLoader(true);
    const nextPage = page + 1;

    fetchCommunityExperiences('12', nextPage, isFeaturedExperiences)
      .then((newExperiences) => {
        setLocalCommunityExperiences((prevExperiences) => [
          ...prevExperiences,
          ...newExperiences,
        ]);
        setPage(nextPage);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [page, loader, totalCommunityExperiencesPages, isFeaturedExperiences]);

  useEffect(() => {
    setPage(0);
    fetchCommunityExperiences('12', 0, isFeaturedExperiences).then(
      (initialExperiences) => {
        setLocalCommunityExperiences(initialExperiences);
        setCommunityExperiencesLoaded(true);
      }
    );
    setIsExperienceDelisted(false);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [
    queryName,
    likes,
    tags,
    mostRecent,
    isFeaturedExperiences,
    isExperienceDelisted,
  ]);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreExperiences();
        }
      },
      { rootMargin: '100px', threshold: 0.5 } // Trigger when 50% of item is in view
    );

    if (lastItemRef.current) {
      observer.current.observe(lastItemRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loadMoreExperiences, localCommunityExperiences]);

  return (
    <Fade in={true} transition={{ enter: { duration: 0.2 } }}>
      <VStack spacing={0} align='stretch' minHeight='90vh'>
        <TopActionBar
          featureIcon={
            <Star
              stroke={isFeaturedExperiences ? '#d3bb8a' : 'white'}
              boxSize={[5]}
            />
          }
          featureTitle='Featured Experiences'
          icon={
            <Globe
              stroke={!isFeaturedExperiences ? '#d3bb8a' : 'white'}
              boxSize={[5]}
            />
          }
          title='Community Experiences'
          showSearchBar={false}
          searchBarPlaceholder='Search Community Experience Name...'
          rightActions={<CommunityBarActions />}
        />
        <Box p={['40px 40px 0px 40px']}>
          <CommunityFilters />
        </Box>
        <Fragment>
          <Box p={['40px']} flex='1'>
            {localCommunityExperiences?.length > 0 ? (
              <SimpleGrid columns={[3, 3, 3, 3, 4]} spacing={[5]}>
                {localCommunityExperiences.map(
                  (communityExperience: CommunityExperienceListItem, index) => (
                    <Skeleton
                      key={communityExperience.id}
                      isLoaded={communityExperiencesLoaded}
                      startColor='#29303C'
                      endColor='transparent'
                      borderRadius='16px'
                      ref={
                        index === localCommunityExperiences.length - 1
                          ? lastItemRef
                          : null
                      }
                    >
                      <CommunityItem
                        communityExperienceListItem={communityExperience}
                      />
                    </Skeleton>
                  )
                )}
              </SimpleGrid>
            ) : (
              <Text fontFamily='Normal' color='white' fontWeight='600'>
                No Community Experience Found
              </Text>
            )}
          </Box>
          {loader && (
            <Text
              fontFamily='Normal'
              color='white'
              fontWeight='600'
              textAlign='center'
            >
              Loading more experiences...
            </Text>
          )}
        </Fragment>
      </VStack>
    </Fade>
  );
};

export default CommunityExperiencesView;
