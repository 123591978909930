import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Back = (props: IconProps) => (
  <Icon
    width='5'
    height='7'
    viewBox='0 0 5 7'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M4 6.5L1 3.5L4 0.5' stroke='white' strokeLinecap='round' />
  </Icon>
);
