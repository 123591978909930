import React, { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import configureAxios from '../axiosClient';
import { useToast } from '@chakra-ui/react';
import { acceptOrganisationInvite } from '../utils/helper';
import { useAuthStore } from '../store/auth';
function DefaultAuthorizedView() {
  const { invited } = useAuthStore((state) => state);
  const [param] = useSearchParams();
  const inviteId = param.get('inviteId');
  const axiosClient = configureAxios();
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  useEffect(() => {
    const handleInvite = async () => {
      if (inviteId && !invited) {
        const response = await acceptOrganisationInvite(inviteId, axiosClient);
        toast({
          title: response.message,
          status: response.status ? 'success' : 'error',
        });
      }
    };

    handleInvite();
  }, [inviteId]);

  return <Navigate to='/' />;
}

export default DefaultAuthorizedView;
