import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';
import { ExperienceListItem, ExperienceSceneItem } from '../interfaces';

interface ExperienceState {
  experienceViewerActive: boolean;
  showEmbedModal: boolean;
  showNearingUsageLimitActive: boolean;
  showLimitReachedModalActive: boolean;
  shareCommunityExperienceModalActive: boolean;
  publishExperienceModalActive: boolean;
  showCreatorToolDashboard: boolean;
  selectedExperience: ExperienceListItem;
  selectedExperienceScenes: ExperienceSceneItem[];
  experiences: ExperienceListItem[];
  filteredExperiences: ExperienceListItem[];
  viewerUnPublishExperienceModalActive: boolean;
  setCreatorToolDashboard: (state: boolean) => void;
  setNearingUsageLimitActive: (state: boolean) => void;
  setLimitReachedModalActive: (state: boolean) => void;
  setShareCommunityExperienceModalActive: (state: boolean) => void;
  setViewerUnPublishExperienceModalActive: (state: boolean) => void;
  setExperienceViewerActive: (state: boolean) => void;
  setShowEmbedModal: (state: boolean) => void;
  setPublishExperienceModalActive: (state: boolean) => void;
  setSelectedExperience: (experiences: ExperienceListItem) => void;
  setSelectedExperienceScenes: (
    experienceScenes: ExperienceSceneItem[]
  ) => void;
  setExperiences: (experiences: ExperienceListItem[]) => void;
  addExperience: (experience: ExperienceListItem) => void;
  renameExperience: (updatedExperience: ExperienceListItem) => void;
  unPublishExperience: (experienceId: string) => void;
  shareCommunityExperience: (experienceId: string) => void;
  deleteExperience: (expIdToDelete: string) => void;
  filterExperiences: (expName: string) => void;
  resetExperiences: () => void;
}

const defaultSelectedExperience: ExperienceListItem = {
  id: '',
  name: '',
  description: '',
  created: '',
  modified: '',
  processed: false,
  preview_url: '',
  published_url: '',
  community_url: '',
  like_count: 0,
  clone_count: 0,
  tags: [],
  is_community_shared: false,
  community_shared_at: '',
};

export const useExperiencesStore = create<ExperienceState>()(
  devtools(
    persist(
      (set) => ({
        experienceViewerActive: false,
        showEmbedModal: false,
        showNearingUsageLimitActive: false,
        showLimitReachedModalActive: false,
        viewerUnPublishExperienceModalActive: false,
        shareCommunityExperienceModalActive: false,
        publishExperienceModalActive: false,
        showCreatorToolDashboard: false,
        experiences: [],
        filteredExperiences: [],
        selectedExperience: defaultSelectedExperience,
        selectedExperienceScenes: [],

        setCreatorToolDashboard: (state: boolean) =>
          set(() => ({ showCreatorToolDashboard: state })),
        setViewerUnPublishExperienceModalActive: (state: boolean) =>
          set(() => ({ viewerUnPublishExperienceModalActive: state })),
        setExperienceViewerActive: (state: boolean) =>
          set(() => ({ experienceViewerActive: state })),
        setNearingUsageLimitActive: (state: boolean) =>
          set(() => ({ showNearingUsageLimitActive: state })),
        setLimitReachedModalActive: (state: boolean) =>
          set(() => ({ showLimitReachedModalActive: state })),
        setShowEmbedModal: (state: boolean) =>
          set(() => ({ showEmbedModal: state })),
        setExperiences: (newExperiences: ExperienceListItem[]) =>
          set((state) => {
            // Remove any experiences that already exist in state.experiences
            const uniqueNewExperiences = newExperiences.filter(
              (newExp) =>
                !state.experiences.some(
                  (existingExp) => existingExp.id === newExp.id
                )
            );
            return {
              experiences: [...state.experiences, ...uniqueNewExperiences],
            };
          }),
        setShareCommunityExperienceModalActive(state) {
          set(() => ({ shareCommunityExperienceModalActive: state }));
        },
        setPublishExperienceModalActive(state) {
          set(() => ({ publishExperienceModalActive: state }));
        },
        setSelectedExperience: (experience: ExperienceListItem) =>
          set(() => ({ selectedExperience: experience })),
        setSelectedExperienceScenes: (
          experienceScenes: ExperienceSceneItem[]
        ) => set(() => ({ selectedExperienceScenes: experienceScenes })),
        addExperience: (experience: ExperienceListItem) =>
          set((state) => ({ experiences: [...state.experiences, experience] })),
        renameExperience: (updatedExperience: ExperienceListItem) =>
          set((state) => ({
            experiences: state.experiences.map(
              (experienceListItem: ExperienceListItem) =>
                experienceListItem.id === updatedExperience.id
                  ? updatedExperience
                  : experienceListItem
            ),
          })),
        deleteExperience: (expIdToDelete: string) =>
          set((state) => ({
            experiences: state.experiences.filter(
              (experienceListItem: ExperienceListItem) =>
                experienceListItem.id !== expIdToDelete
            ),
          })),
        unPublishExperience: (expIdToDelete: string) =>
          set((state) => ({
            experiences: state.experiences.filter(
              (experienceListItem: ExperienceListItem) =>
                experienceListItem.id !== expIdToDelete
            ),
          })),

        shareCommunityExperience: (expIdToDelete) =>
          set((state) => ({
            experiences: state.experiences.filter(
              (experienceListItem: ExperienceListItem) =>
                experienceListItem.id !== expIdToDelete
            ),
          })),
        filterExperiences: (expName: string) =>
          set((state) => ({
            filteredExperiences: state.experiences.filter(
              (experienceListItem: ExperienceListItem) =>
                experienceListItem.name
                  .toLowerCase()
                  .includes(expName.toLowerCase())
            ),
          })),
        resetExperiences: () =>
          set(() => ({
            experienceViewerActive: false,
            showEmbedModal: false,
            experiences: [],
            filteredExperiences: [],
            selectedExperience: defaultSelectedExperience,
          })),
      }),
      {
        partialize: () => ({
          filteredExperiences: [],
          selectedExperienceScenes: [],
        }),
        name: 'experiences-storage',
      }
    )
  )
);
