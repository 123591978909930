import {
  Box,
  Fade,
  SimpleGrid,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import { useExperiencesStore } from '../store/experiences';
import DeleteExperienceModal from '../components/modals/DeleteExperienceModal';
import RenameExperienceModal from '../components/modals/RenameExperienceModal';
import CreateExperienceModal from '../components/modals/CreateExperienceModal';
import { ExperienceListItem } from '../interfaces';
import { Experiences } from '../Icons/Experiences';
import TopActionBar from '../components/common/TopActionBar';
import ExperiencesBarActions from '../components/barActions/ExperiencesBarActions';
import ExperienceItem from '../components/experiencesView/ExperienceItem';
import CreateExperienceCard from '../components/cards/CreateExperienceCard';
import { useUserStore } from '../store/user';
import configureAxios from '../axiosClient';
import React from 'react';
import ContactModal from '../components/modals/ContactModal';
import UnPublishExperienceModal from '../components/modals/UnPublishExperienceModal';
import UpdateSourceMaterialsModal from '../components/modals/UpdateSourceMaterialsModal';
import { v4 as uuid } from 'uuid';
import CommunityDashboard from '../components/communityView/CommunityDashboard';
import { useNavigate } from 'react-router-dom';
import { NextArrow } from '../Icons/NextArrow';
import { Home } from '../Icons/Home';

const axiosClient = configureAxios();

function ExperiencesView() {
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });
  const navigate = useNavigate();

  const [searchBarText, setSearchBarText] = useState('');
  const [changeInExperience, setChangeInExperience] = useState(false);
  const [experiencesLoaded, setExperiencesLoaded] = useState(true);
  const [renameModalActive, setRenameModalActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [createModalActive, setCreateModalActive] = useState(false);

  const [contactModalActive, setContactModalActive] = useState(false);
  const [hoveredExperience, setHoveredExperience] = useState<string | null>(
    null
  );
  const [stopFetching, setStopFetching] = useState(false);
  const [updateSourceModalActive, setUpdateSourceModalActive] = useState(false);
  const [totalProgressCompleted, setTotalProgressCompleted] = useState(0);
  const [experiencesToShow, setExperiencesToShow] = useState<
    ExperienceListItem[]
  >([]);

  const [page] = useState(1);
  const [size] = useState(8);

  function debounce<T extends (...args: string[]) => void>(
    func: T,
    delay: number
  ): (...args: Parameters<T>) => void {
    let timeoutId: ReturnType<typeof setTimeout>;

    return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const organisation = useUserStore((state) => state.organisation);
  const {
    filteredExperiences,
    setExperiences,
    setSelectedExperience,
    renameExperience,
    deleteExperience,
    unPublishExperience,
    filterExperiences,
    setShowEmbedModal,
    viewerUnPublishExperienceModalActive,
    setViewerUnPublishExperienceModalActive,
    setShareCommunityExperienceModalActive,
    setCreatorToolDashboard,
  } = useExperiencesStore();

  const debouncedFilter = debounce(filterExperiences, 500);
  const fetchExperiences = async () => {
    const userOrgId = organisation.organizationId;
    if (userOrgId?.length > 0) {
      setExperiencesLoaded(true);
      try {
        const res = await axiosClient.get(
          `org/${userOrgId}/experiences?type=all&page=${page}&size=${size}`
        );
        const experiencesData = res?.data?.data?.experiences || [];
        const sortedExperiences = experiencesData?.sort(
          (a: ExperienceListItem, b: ExperienceListItem) =>
            a.created &&
            b.created &&
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );
        setExperiencesToShow(sortedExperiences);
        setExperiences(sortedExperiences);
        setExperiencesLoaded(false);
        setChangeInExperience(false);
        sortedExperiences.length === 0
          ? setCreatorToolDashboard(true)
          : setCreatorToolDashboard(false);

        if (
          experiencesData?.length === 0 ||
          experiencesData.every((exp: ExperienceListItem) => exp.processed)
        ) {
          setStopFetching(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchExperiences();

    const intervalId = setInterval(() => {
      fetchExperiences();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [organisation.organizationId, changeInExperience, stopFetching]);

  const openDeleteExperienceModal = (
    experienceListItem: ExperienceListItem
  ) => {
    setSelectedExperience(experienceListItem);
    setDeleteModalActive(true);
  };

  const unPublishExperienceModal = (experienceListItem: ExperienceListItem) => {
    setSelectedExperience(experienceListItem);
    setViewerUnPublishExperienceModalActive(true);
  };

  const shareCommunityExperienceModal = (
    experienceListItem: ExperienceListItem
  ) => {
    setSelectedExperience(experienceListItem);
    setShareCommunityExperienceModalActive(true);
  };

  const openRenameExperienceModal = (
    experienceListItem: ExperienceListItem
  ) => {
    setSelectedExperience(experienceListItem);
    setRenameModalActive(true);
  };

  const openEmbedExperienceModal = (experienceListItem: ExperienceListItem) => {
    setSelectedExperience(experienceListItem);
    setShowEmbedModal(true);
  };

  const searchExperiences = (value: string) => {
    setSearchBarText(value);
    debouncedFilter(value);
  };

  const openUpdateSourceMaterialsModal = (
    experienceListItem: ExperienceListItem
  ) => {
    setSelectedExperience(experienceListItem);
    setUpdateSourceModalActive(true);
  };

  const getS3PresignedUrl = async (
    org_id: string,
    file: File,
    exp_id?: string | undefined
  ): Promise<{
    url: string;
    exp_id: string;
    payload_id: string;
  }> => {
    const params = {
      owner: org_id,
      exp_id: exp_id || uuid(),
      payload_id: uuid(),
      content_type: file.type,
      payload_size: new Uint8Array(await file.arrayBuffer()).length.toString(),
    };
    return axiosClient
      .post(
        process.env.REACT_APP_API_URL + 'experience/generate_presigned_url',
        params
      )
      .then((res) => {
        const data = res.data.data;
        const isStatusOk = res.data.status === 'OK';

        if (isStatusOk) {
          if (!data.exceeds)
            return {
              url: data.url,
              exp_id: params.exp_id,
              payload_id: params.payload_id,
            };
          else {
            toast({
              title: data.message,
              status: 'error',
            });
            return { url: '', exp_id: '', payload_id: '' };
          }
        } else {
          toast({
            title: data.message,
            status: 'error',
          });
          return { url: '', exp_id: '', payload_id: '' };
        }
      })
      .catch((err) => {
        console.error(err);
        return { url: '', exp_id: '', payload_id: '' };
      });
  };

  const sendPayloadToS3 = async (url: string, file: File): Promise<boolean> => {
    setTotalProgressCompleted(0);
    return axiosClient
      .put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (ProgressEvent) => {
          if (ProgressEvent.total) {
            const progressCompleted = Math.round(
              (ProgressEvent.loaded * 100) / ProgressEvent.total
            );
            setTotalProgressCompleted(progressCompleted);
          }
        },
      })
      .then((res) => (res.status === 200 ? true : false))
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  return (
    <Fade in={true} transition={{ enter: { duration: 0.2 } }}>
      <UpdateSourceMaterialsModal
        totalProgressCompleted={totalProgressCompleted}
        updateSourceModalActive={updateSourceModalActive}
        setUpdateSourceModalActive={(state: boolean) =>
          setUpdateSourceModalActive(state)
        }
        setChangeInExperience={(state) => setChangeInExperience(state)}
        getS3PresignedUrl={(
          org_id: string,
          file: File,
          exp_id?: string | undefined
        ) => getS3PresignedUrl(org_id, file, exp_id)}
        sendPayloadToS3={(url: string, file: File) =>
          sendPayloadToS3(url, file)
        }
      />
      <RenameExperienceModal
        renameModalActive={renameModalActive}
        setRenameModalActive={(state: boolean) => setRenameModalActive(state)}
        renameExperience={(updatedExperience) => {
          renameExperience(updatedExperience);
          setRenameModalActive(false);
        }}
      />
      <UnPublishExperienceModal
        unPublishExperienceModalActive={viewerUnPublishExperienceModalActive}
        setUnPublishExperienceModalActive={(state: boolean) =>
          setViewerUnPublishExperienceModalActive(state)
        }
        unPublishExperience={(experienceId: string) => {
          unPublishExperience(experienceId);
          setViewerUnPublishExperienceModalActive(false);
        }}
        fetchExperiences={fetchExperiences}
      />
      <DeleteExperienceModal
        deleteModalActive={deleteModalActive}
        setDeleteModalActive={(state: boolean) => setDeleteModalActive(state)}
        deleteExperience={(experienceId: string) => {
          deleteExperience(experienceId);
          setDeleteModalActive(false);
        }}
      />
      <CreateExperienceModal
        totalProgressCompleted={totalProgressCompleted}
        createModalActive={createModalActive}
        setChangeInExperience={(state) => setChangeInExperience(state)}
        setCreateModalActive={(state: boolean) => setCreateModalActive(state)}
        getS3PresignedUrl={(
          org_id: string,
          file: File,
          exp_id?: string | undefined
        ) => getS3PresignedUrl(org_id, file, exp_id)}
        sendPayloadToS3={(url: string, file: File) =>
          sendPayloadToS3(url, file)
        }
      />
      <TopActionBar
        icon={<Home stroke='white' boxSize={[5]} />}
        title='Home'
        showSearchBar={false}
        searchBarPlaceholder='Search Experience Name...'
        rightActions={
          <ExperiencesBarActions
            createExperience={() => setCreateModalActive(true)}
          />
        }
        searchBarText={searchBarText}
        onSearchBarTextChange={(value) => searchExperiences(value)}
      />

      <ContactModal
        contactModalActive={contactModalActive}
        setContactModalActive={setContactModalActive}
      />
      <Box display='flex' flexDirection='column' gap={5} mt={5}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          p={['5px 40px 0px 40px']}
        >
          <Box display='flex' alignItems='center' gap={2}>
            <Experiences stroke={'white'} boxSize={5} />
            <Text fontFamily='Metal' fontSize='14px' textTransform='uppercase'>
              Recent Experiences
            </Text>
          </Box>
          <Box display='flex' alignItems='center' gap={10}>
            {experiencesToShow.length > 0 && (
              <Box
                display='flex'
                alignItems='center'
                cursor='pointer'
                gap={2}
                onClick={() => {
                  navigate('/experiences');
                }}
              >
                <Text fontSize='11px'>View All</Text>
                <NextArrow boxSize={5} />
              </Box>
            )}
          </Box>
        </Box>
        {experiencesToShow.length > 0 && (
          <Fragment>
            <Box p={['0 40px']}>
              <SimpleGrid columns={[3, 3, 3, 3, 4]} spacing={[5]}>
                {searchBarText?.length > 0 ? (
                  filteredExperiences?.length > 0 ? (
                    filteredExperiences?.map(
                      (experienceListItem: ExperienceListItem) => (
                        <Skeleton
                          key={experienceListItem.id}
                          isLoaded={!experiencesLoaded}
                          startColor='#29303C'
                          endColor='transparent'
                          borderRadius='16px'
                        >
                          <ExperienceItem
                            key={experienceListItem.id}
                            experienceListItem={experienceListItem}
                            experienceHovered={
                              hoveredExperience === experienceListItem.id
                            }
                            renameExperience={() =>
                              openRenameExperienceModal(experienceListItem)
                            }
                            shareCommunityExperience={() =>
                              shareCommunityExperienceModal(experienceListItem)
                            }
                            unPublishExperience={() =>
                              unPublishExperienceModal(experienceListItem)
                            }
                            deleteExperience={() =>
                              openDeleteExperienceModal(experienceListItem)
                            }
                            openEmbedModal={() =>
                              openEmbedExperienceModal(experienceListItem)
                            }
                            hoverExperience={() =>
                              setHoveredExperience(experienceListItem.id)
                            }
                            unHoverExperience={() => setHoveredExperience('')}
                            updateSourceMaterials={() =>
                              openUpdateSourceMaterialsModal(experienceListItem)
                            }
                          />
                        </Skeleton>
                      )
                    )
                  ) : (
                    <Skeleton
                      isLoaded={!experiencesLoaded}
                      startColor='#29303C'
                      endColor='transparent'
                      borderRadius='16px'
                    >
                      <CreateExperienceCard
                        createExperience={() => setCreateModalActive(true)}
                      />
                    </Skeleton>
                  )
                ) : experiencesToShow?.length > 0 ? (
                  experiencesToShow?.map(
                    (experienceListItem: ExperienceListItem) => (
                      <ExperienceItem
                        key={experienceListItem.id}
                        experienceListItem={experienceListItem}
                        experienceHovered={
                          hoveredExperience === experienceListItem.id
                        }
                        renameExperience={() =>
                          openRenameExperienceModal(experienceListItem)
                        }
                        unPublishExperience={() =>
                          unPublishExperienceModal(experienceListItem)
                        }
                        shareCommunityExperience={() =>
                          shareCommunityExperienceModal(experienceListItem)
                        }
                        deleteExperience={() =>
                          openDeleteExperienceModal(experienceListItem)
                        }
                        openEmbedModal={() =>
                          openEmbedExperienceModal(experienceListItem)
                        }
                        hoverExperience={() =>
                          setHoveredExperience(experienceListItem.id)
                        }
                        unHoverExperience={() => setHoveredExperience('')}
                        updateSourceMaterials={() =>
                          openUpdateSourceMaterialsModal(experienceListItem)
                        }
                      />
                    )
                  )
                ) : (
                  <Skeleton
                    isLoaded={!experiencesLoaded}
                    startColor='#29303C'
                    endColor='transparent'
                    borderRadius='16px'
                  >
                    <CreateExperienceCard
                      createExperience={() => setCreateModalActive(true)}
                    />
                  </Skeleton>
                )}
              </SimpleGrid>
            </Box>
          </Fragment>
        )}
        <CommunityDashboard />
      </Box>
    </Fade>
  );
}

export default ExperiencesView;
