import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Home = (props: IconProps) => (
  <Icon
    width='22'
    height='20'
    viewBox='0 0 22 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.8288 0.262441C11.8202 0.2539 11.8114 0.245547 11.8023 0.237393C11.633 0.0856363 11.4111 0 11.1821 0H10.8177C10.585 0 10.365 0.0871815 10.1975 0.237374C10.1975 0.23738 10.1975 0.237367 10.1975 0.237374L0.329018 9.08426C-0.302537 9.65208 0.0962461 10.7035 0.949947 10.7035H2.04183V19.0713C2.04183 19.5837 2.45811 20 2.97049 20H8.60015C9.11253 20 9.52881 19.5837 9.52881 19.0713V12.8493H12.471V19.0713C12.471 19.5837 12.8873 20 13.3996 20H19.0279C19.5403 20 19.9566 19.5837 19.9566 19.0713V10.7035H21.047C21.9007 10.7035 22.2995 9.65208 21.668 9.08426L11.8288 0.262441ZM10.9992 1.40839L2.20022 9.29647H2.52023C3.0326 9.29647 3.44889 9.71276 3.44889 10.2251V18.5929H8.12175V12.3709C8.12175 11.8585 8.53804 11.4422 9.05041 11.4422H12.9494C13.4618 11.4422 13.878 11.8585 13.878 12.3709V18.5929H18.5495V10.2251C18.5495 9.71275 18.9658 9.29647 19.4782 9.29647H19.7969L10.9992 1.40839Z'
      fill='white'
    />
  </Icon>
);
