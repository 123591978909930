import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import FormWrapper from '../common/FormWrapper';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useState } from 'react';
import { useAuthStore } from '../../store/auth';
import configureAxios from '../../axiosClient';
import React from 'react';
import { AxiosResponse } from 'axios';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { dispatchUserToAnalytics } from '../../analytics';
import { useSearchParams } from 'react-router-dom';

const axiosClient = configureAxios();

type OTPFormValues = {
  otp: string;
};

interface OTPFormProps {
  enteredEmail: string;
  setEnteredEmail: (email: string) => void;
}

function OTPForm({ enteredEmail, setEnteredEmail }: OTPFormProps) {
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors, isSubmitting },
  } = useForm<OTPFormValues>();
  const toast = useToast({
    position: 'top',
    variant: 'top-accent',
  });

  const {
    isRequestPending,
    setUserExists,
    setLoggedIn,
    setActiveLoginView,
    setIsRequestPending,
    setInvited,
  } = useAuthStore();
  const [incorrectOTP, setIncorrectOTP] = useState(false);
  const [OTPLoading, setOTPLoading] = useState(false);

  const [searchParam] = useSearchParams();
  const inviteId = searchParam.get('inviteId');
  const onSubmit: SubmitHandler<OTPFormValues> = (data) => {
    setOTPLoading(true);

    // Check if there's already a timeout set
    if (!isRequestPending) {
      setTimeout(() => {
        setIsRequestPending(false);
      }, 5 * 60 * 1000);
    }

    axiosClient
      .post(`auth/otp`, {
        email: enteredEmail,
        code: data.otp,
        inviteId: inviteId ?? '',
      })
      .then((res: AxiosResponse) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          setOTPLoading(false);
          setValue('otp', '');

          const isNewUser = res.data.data.new_user === true;
          const userBelongsToAnOrg =
            res.data.data.belongs_to_organisation === true;
          const shouldGoToDashboard = isNewUser && !userBelongsToAnOrg;
          const invitedSuccessfully = res.data.data.invited_successfully;
          const inviteMsg = res.data.data.invite_msg;
          if (invitedSuccessfully !== undefined) {
            setInvited(true);
            if (invitedSuccessfully) {
              toast({
                id: 'inviteSuccess',
                title: inviteMsg,
                status: 'success',
              });
            } else {
              toast({
                id: 'inviteFailed',
                title: inviteMsg,
                status: 'error',
              });
            }
          }
          setUserExists(!isNewUser);

          if (!shouldGoToDashboard) {
            setLoggedIn(true);
          } else {
            setActiveLoginView('createAccount');
          }
          dispatchUserToAnalytics('login', {
            organisation_user_id: res.data.data.user_id,
            organisation_id: res.data.data.org_id,
          });
        } else {
          setOTPLoading(false);
          setIncorrectOTP(true);
        }

        setOTPLoading(false);
      })
      .catch((err: unknown) => {
        console.log(err);
        setOTPLoading(false);
        setIncorrectOTP(true);
      });
  };

  const resendOTP = () => {
    if (isRequestPending) {
      if (!toast.isActive('otpRetry')) {
        toast({
          id: 'otpRetry',
          title: `Please retry after 5 minutes`,
          status: 'error',
        });
      }
      return;
    }

    setIsRequestPending(true);

    axiosClient
      .post(`auth/login`, {
        email: enteredEmail,
      })
      .then((res: AxiosResponse) => {
        const isStatusOk = res.data.status === 'OK';
        if (isStatusOk) {
          if (!toast.isActive('otpSuccess')) {
            toast({
              id: 'otpSuccess',
              title: `New OTP was sent successfully`,
              status: 'success',
            });
          }
        } else {
          if (!toast.isActive('otpError')) {
            toast({
              id: 'otpError',
              title: `There was an error sending another OTP`,
              status: 'error',
            });
          }
        }
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)} name='otp_form'>
      {OTPLoading ? (
        <Box
          w={['100%']}
          height={['100px']}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Spinner size='md' />
        </Box>
      ) : (
        <Box
          w={['100%']}
          height={['auto']}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          gap={3}
        >
          <FormControl
            isInvalid={errors.otp ? true : false}
            display='flex'
            flexDirection='column'
            alignItems='center'
          >
            <FormLabel
              textAlign='center'
              htmlFor='otp'
              color='white'
              fontFamily='Normal'
              fontSize={['12px', '12px', '12px']}
              mb='0px'
            >
              Please enter verification code sent to&nbsp;
              <span style={{ color: '#D3BB8A', fontWeight: '900' }}>
                {enteredEmail}
              </span>
            </FormLabel>
            <Input
              m='0 auto'
              width={['50%']}
              type='number'
              id='otp'
              placeholder='000000'
              textAlign='center'
              color='white'
              borderBottom='1px solid #D6DBE4'
              variant='flushed'
              fontFamily='Normal'
              fontSize={['30px', '30px', '30px']}
              size='lg'
              _placeholder={{ color: '#7F8B9E' }}
              errorBorderColor='#EA1A4C'
              {...register('otp', {
                required: 'Please enter a correct OTP',
                minLength: {
                  value: 6,
                  message: 'OTP should be minimum 6 numbers',
                },
                maxLength: {
                  value: 6,
                  message: 'OTP should be maximum 6 numbers',
                },
              })}
            />
            <FormErrorMessage
              color='#EA1A4C'
              mt={['4px']}
              fontSize='13px'
              fontFamily='Normal'
            >
              {errors.otp && errors.otp.message}
            </FormErrorMessage>
          </FormControl>

          <Text
            color='#EA1A4C'
            mt={['4px']}
            fontSize='13px'
            fontFamily='Normal'
          >
            {incorrectOTP && 'Incorrect Code'}
          </Text>

          <Box display='flex' alignItems='center' gap={1}>
            <Text
              color='white'
              fontFamily='Normal'
              fontSize={['12px', '12px', '12px']}
            >
              {"Didn't get the code?"}
            </Text>
            <Button
              textDecoration='underline'
              variant='link'
              color='white'
              fontSize={['12px', '12px', '12px']}
              onClick={() => resendOTP()}
            >
              Resend
            </Button>
          </Box>

          <Button
            textTransform='uppercase'
            width={['80%', '80%', '300px']}
            variant='outline'
            color='#7F8B9E'
            background='#1D2531'
            border='1px solid rgba(242, 243, 245, 0.14)'
            fontFamily='Metal'
            fontSize={['14px', '14px', '15px']}
            borderRadius='30px'
            _hover={{
              bg: 'white',
              color: '#08101D',
            }}
            isLoading={isSubmitting}
            type='submit'
          >
            Verify
          </Button>

          <Button
            mt='10px'
            leftIcon={<ChevronLeftIcon boxSize={[4]} color='currentColor' />}
            color='white'
            fontSize='12px'
            variant='link'
            fontFamily='Normal'
            onClick={() => {
              setActiveLoginView('login');
              setEnteredEmail('');
            }}
          >
            Go Back
          </Button>
        </Box>
      )}
    </FormWrapper>
  );
}

export default OTPForm;
